import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/CAT/layout'

import pic11 from '../../assets/imgs/landing/p1.jpg'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>Unidans Project</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1> Objectius </h1>
                    </header>
                    <span className="image main"><img src={pic11} alt="" /></span>
                    
                    <h3> Objectius generals </h3>
                        <ul>
                            <li> Entendre la diversitat dels límits humans en el moviment i en relació amb el propi cos.</li> 
                            <li>  Desenvolupar programes i serveis per a totes les categories de persones amb o sense
discapacitat. </li>
                            <li> Crear un vincle sòlid entre
l’esport i els sectors compromesos amb la inclusió social, a part d’incrementar l’interès per
l’esport entre les persones físicament inactives. </li>
                            <li>Maximitzar la contribució de l’esport en l’assoliment dels
objectius de l’Estratègia Europa 2020 per un creixement intel·ligent, sostenible i inclusiu.</li> 
                            
                        
                        </ul>

                        <h3> Objectius específics
 </h3>
        <ul>


            <li>Comprendre les necessitats dels joves amb o sense discapacitat per la seva
integració a programes educatius de dansa. </li>
            <li> Desenvolupar
oportunitats en l’àmbit de la dansa per a persones amb discapacitat. </li>
            <li> Fomentar l’intercanvi de coneixements i bones
practiques en l’àmbit de la dansa i l’esport adaptat/unificat entre les parts interessades. </li>
            <li> Fomentar el diàleg intercultural mitjançant la
inclusió de persones amb diferents orígens culturals a través de l’esport als tres països
participants. </li>
        </ul>

                </div>
            </section>
        </div>

    </Layout>
)

export default Generic